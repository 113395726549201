<template>
  <div>
    <m-bread-crumb/>

<section class="car-details spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="car__details__pic">
                        <div class="car__details__pic__large">
                            <a href="#" target="_blank"><img class="car-big-img" src="img/cars/details/cd-11.jpg" alt=""></a>
                        </div>
                    </div>



<div id="accordion">

<div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne"> 
         Demonstrações Financeiras de 2021
        </button>
              
      </h5>
    </div>
    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2021/Disciplina_de_Mercado_Junho_2021.pdf"> Disciplina de Mercado – Junho 2021</a>
        </ul>
      </div>
    </div>
  
    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2021/Indicadores_Prudenciais_Junho_2021.pdf"> Indicadores Prudenciais – Junho 2021</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2021/Indicadores_Prudenciais_Março_2021.pdf"> Indicadores Prudenciais – Março 2021</a>
        </ul>
      </div>
    </div>
    
    
    <!-- <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2021/Relatorio_Intercalar_2021.pdf"> Relatório Intercalar – 2021</a>
        </ul>
      </div>
    </div> -->

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2021/Relatorio_Intercalar_2021.pdf"> Relatório Intercalar – 2021</a>
        </ul>
      </div>
    </div>
    
</div>

<div class="card">
    <div class="card-header" id="headingTwo">
      <h5 class="mb-0">
        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-controls="collapseTwo">
          Demonstrações Financeiras de 2020
        </button>
      </h5>
    </div>

<!-- <div id="collapseTwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion"> // Para deixar aberto: aria-expanded="true" -->
<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            <a href="report/2020/Disciplina de Mercado - Dezembro 2020.pdf"> Disciplina de Mercado - Dezembro 2020</a>
        </ul>
      </div>
</div>

<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            <a href="report/2020/Disciplina de Mercado - Junho 2020.pdf"> Disciplina de Mercado - Junho 2020</a>
        </ul>
      </div>
</div>

    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            <a href="report/2020/Indicadores Prudenciais- Dezembro 2020.pdf"> Indicadores Prudenciais- Dezembro 2020</a>
        </ul>
      </div>
    </div>

    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            <a href="report/2020/Indicadores_Prudenciais_Setembro_2020.pdf"> Indicadores Prudenciais- Setembro 2020</a>
        </ul>
      </div>
    </div>

    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            <a href="report/2020/Indicadores_Prudenciais_Junho_2020.pdf"> Indicadores Prudenciais- Junho 2020</a>
        </ul>
      </div>
    </div>

<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            <a href="report/2020/Indicadores_Prudenciais_Marco_2020.pdf"> Indicadores Prudenciais – Março 2020</a>
        </ul>
      </div>
</div>

<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            <a href="report/2020/Relatorio_intercalar_disciplina_2020.pdf">Relatório Intercalar – 2020</a>
        </ul>
      </div>
</div>

<div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
        <ul>
            <i class="fa fa-chevron-circle-right" style="color:red;"></i>
            <a href="report/2020/Relatorio e Contas 2020.pdf">Relatório e Contas – 2020</a>
        </ul>
      </div>
</div>


    
</div>
  
<div class="card">
    <div class="card-header" id="headingThree">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree"> 
         Demonstrações Financeiras de 2019
        </button>
              
      </h5>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2019/Relatorio_anual_disciplina_mercado_2019.pdf"> Disciplina de Mercado – Dezembro 2019</a>
        </ul>
      </div>
    </div>
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2019/disciplina_mercado_jun_2019.pdf"> Disciplina de Mercado – Junho 2019</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2019/Indicadores_Prudenciais_Dezembro_2019.pdf"> Indicadores Prudenciais – Dezembro 2019</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2019/Indicadores_Prudenciais_Junho_2019.pdf"> Indicadores Prudenciais – Junho 2019</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2019/Indicadores_Prudenciais_Marco_2019"> Indicadores Prudenciais – Março 2019</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2019/Indicadores_Prudenciais_Setembro_2019.pdf"> Indicadores Prudenciais – Setembro 2019</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2019/Relatorio_anual_disciplina_mercado_2019.pdf"> Relatório e Contas – 2019</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2019/Relatorio_anual_disciplina_mercado_2019.pdf"> Relatório Intercalar – 2019</a>
        </ul>
      </div>
    </div>
    
</div>
    
    
<div class="card">
    <div class="card-header" id="headingFour">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour"> 
         Demonstrações Financeiras de 2018
        </button>
              
      </h5>
    </div>
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2018/disciplina_mercado_jun_2018.pdf"> Disciplina de Mercado – Junho 2018</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2018/disciplina_mercado_dez_2018.pdf"> Disciplina de Mercado – Dezembro 2018</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2018/Indicadores_Prudenciais_Dezembro_2018.pdf"> Indicadores Prudenciais – Dezembro 2018</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2018/Indicadores_Prudenciais_Junho_2018.pdf"> Indicadores Prudenciais – Junho 2018</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2018/Indicadores_Prudenciais_Marco_2018"> Indicadores Prudenciais – Março 2018</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2018/Indicadores_Prudenciais_Setembro_2018.pdf"> Indicadores Prudenciais – Setembro 2018</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2018/relatorio_contas_2018.pdf"> Relatório de Contas – 2018</a>
        </ul>
      </div>
    </div>
    
    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2019/relatorio_intercalar_jun_2018.pdf"> Relatório Intercalar – 2018</a>
        </ul>
      </div>
    </div>
    
</div>
    
<div class="card">
    <div class="card-header" id="headingFive">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive"> 
         Demonstrações Financeiras de 2017
        </button>
              
      </h5>
    </div>

    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2017/Indicadores_prudenciais_Dezembro_2017.pdf"> Indicadores Prudenciais Dezembro 2017</a>
        </ul>
      </div>
    </div>

    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2017/Disciplina de Mercado - Dezembro 2017.pdf"> Disciplina de Mercado - Dezembro 2017</a>
        </ul>
      </div>
    </div>

    <!-- <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2017/Indicadores_prudenciais_Dezembro_2017.pdf"> Indicadores Prudenciais – Junho 2017</a>
        </ul>
      </div>
    </div> -->
    
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2017/Relatório e Contas 2017.pdf"> Relatório e Contas 2017</a>
        </ul>
      </div>
    </div>
    
    
    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2018/Relatório Intercalar 2017.pdf"> Relatório Intercalar 2017</a>
        </ul>
      </div>
    </div>

    <!-- <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2018/relatorio_anual_2017.pdf"> Relatório Anual</a>
        </ul>
      </div>
    </div> -->
    
    
</div>
    
<!-- <div class="card">
    <div class="card-header" id="headingSix">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix"> 
         Demonstrações Financeiras de 2015
        </button>
              
      </h5>
    </div>
    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2015/relatorio_contas_intercalar_2015.pdf"> Relatório de Contas Intercalar – Junho 2015</a>
        </ul>
      </div>
    </div>
    
    
</div> -->
    
<!-- <div class="card">
    <div class="card-header" id="headingSeven">
      <h5 class="mb-0" >
        <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven"> 
         Demonstrações Financeiras de 2013
        </button>
              
      </h5>
    </div>
    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
      <div class="card-body">
        <ul>
           <i class="fa fa-chevron-circle-right" style="color:red;"></i> <a href="report/2013/relatorio_contas_intercalar_2013.pdf"> Relatório de Contas Intercalar – Junho 2013</a>
        </ul>
      </div>
    </div>
    
    
</div> -->
    

</div>

                </div>
                <div class="col-lg-3">
                    <div class="car__details__sidebar">
                        <div class="car__details__sidebar__model">
                            <ul>
                                <li>Relatório <span>2021</span></li>
                            </ul>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        

        
        
    </section>
    
    

    
  </div>
</template>

<script>
import MBreadCrumb from "@/components/Layout/BreadCrumb";

export default {
  name: "Reports",
  components: { MBreadCrumb }
}
</script>

<style scoped>

</style>